export default {
    "name": "UIDIsAvailable",
    "kind": "HoudiniQuery",
    "hash": "d5e8ea0dab3ebbe0c6957b751a6ca7ecb26097414306a5eb450e10b999d85616",

    "raw": `query UIDIsAvailable($uid: UID!) {
  uidIsAvailable(uid: $uid)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "uidIsAvailable": {
                "type": "Boolean",
                "keyRaw": "uidIsAvailable(uid: $uid)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "set_blocking": true
        }
    },

    "input": {
        "fields": {
            "uid": "UID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "CacheAndNetwork",
    "partial": false
};

"HoudiniHash=15dc58baef84bda06ec210d604cf9918f4575b4d0915cf4439e1318e78d2d4a5";